//styles rules, constraint sets, resposive params

export const breakpoints = {
  default: '0px',
  minimum: '320',
  mobile2: '375',
  mobile3: '480',
  tablet: '768',
  laptop1: '1080',
  laptop2: '1440',
  desktop1: '1920',
  desktop2: '2560',
};

export const screenMargins = {
    default: '16px',
    mobileS: '24px',
    mobileM: '32px',
    mobileL: '32px',
    tablet: '32px',
    laptop: '144px',
    laptopL:'144px',
    desktop: '96px',
    desktopL: '96px',
};

export const gridGutter = {
  default: {px: '8px', rem: '.5rem'},
  mobileS:  {px: '16px', rem: '1rem'},
  mobileM:  {px: '24px', rem: '1.5rem'},
  mobileL: {px: '24px', rem: '1.5rem'},
  tablet: {px: '32px', rem: '2rem'},
  laptop: {px: '32px', rem: '2rem'},
  laptopL: {px: '32px', rem: '2rem'},
  desktop: {px: '32px', rem: '2rem'},
  desktopL: {px: '32px', rem: '2rem'},
};
export const moduleSpacing = {
  default: '48px',
  mobileS: '56px',
  mobileM: '56px',
  mobileL: '56px',
  tablet: '64px',
  laptop: '64px',
  laptopL: '64px',
  desktop: '128px',
  desktopL: '128px',
}

export const gridColumns = {
  default: '4',
  mobileS: '4',
  mobileM: '4',
  mobileL: '4',
  tablet: '6',
  laptop: '6',
  laptopL: '6',
  desktop: '6',
  desktopL: '6',
};

export const stroke = {
  '1': {solid: '1px solid', dotted: '1px dotted'},
  '4': {solid: '4px solid', dotted: '4px dotted'},
}

export const color = {
  'black': {hex: '#222', rgb: 'rgb(34,34,34)'},
  'mid-grey': {hex: '#E1E1E1', rgb: 'rgb(225,225,225)'},
  'bg-grey': {hex: '#EDEDED', rgb: 'rgb(237,237,237)'},
}

export const contentMaxWidth = {
  body: '1664px',
}

export const device = {
  default: `only screen and (min-width: ${breakpoints.default}px)`,
  mobileS: `only screen and (min-width: ${breakpoints.minimum}px)`,
  mobileM: `only screen and (min-width: ${breakpoints.mobile2}px)`,
  mobileL: `only screen and (min-width: ${breakpoints.mobile3}px)`,
  tablet: `only screen and (min-width: ${breakpoints.tablet}px)`,
  laptop: `only screen and (min-width: ${breakpoints.laptop1}px)`,
  laptopL: `only screen and (min-width: ${breakpoints.laptop2}px)`,
  desktop: `only screen and (min-width: ${breakpoints.desktop1}px)`,
  desktopL: `only screen and (min-width: ${breakpoints.desktop2}px)`
};

const unitScale = 
[0,8,16,24,32,40,48,56,64,72,80,88,96,112,120,128,144];

const fontScaleHeaders = 
[20,24,28,32,40,48,56,64,72,80,88,96,112,120,128];

export const spacers = {
  '1': {px: '1px', rem: '.0625rem'},
  '4': {px: '4px', rem: '.25rem'},
  '8': {px: '8px', rem: '.5rem'},
  '16': {px: '16px', rem: '1rem'},
  '20': {px: '20px', rem: '1.25rem'},
  '24': {px: '24px', rem: '1.5rem'},
  '32': {px: '32px', rem: '2rem'},
  '40': {px: '40px', rem: '2.5rem'},
  '48': {px: '48px', rem: '3rem'},
  '56': {px: '56px', rem: '3.5rem'},
  '64': {px: '64px', rem: '4rem'},
  '72': {px: '72px', rem: '4.5rem'},
  '80': {px: '80px', rem: '5rem'},
  '88': {px: '88px', rem: '5.5rem'},
  '96': {px: '96px', rem: '6rem'},
  '112': {px: '112px', rem: '7rem'},
  '120': {px: '120px', rem: '7.5rem'},
  '128': {px: '128px', rem: '8rem'},
  '144': {px: '144px', rem: '9rem'},
  '160': {px: '160px', rem: '10rem'},
  '176': {px: '176px', rem: '11rem'},
  '184': {px: '184px', rem: '11.5rem'},
  '240': {px: '240px', rem: '15rem'},
  '264': {px: '264px', rem: '16.5rem'},
  '296': {px: '296px', rem: '18.5rem'},
  '312': {px: '312px', rem: '19.5rem'}
}

export const fontScale = {
  '14': {px: '14px', rem: '.88rem'},
  '16': {px: '16px', rem: '1rem'},
  '18': {px: '18px', rem: '1.125rem'},
  '20': {px: '20px', rem: '1.25rem'},
  '24': {px: '24px', rem: '1.5rem'},
  '28': {px: '28px', rem: '1.75rem'},
  '32': {px: '32px', rem: '2rem'},
  '36': {px: '36px', rem: '2.25rem'},
  '40': {px: '40px', rem: '2.5rem'},
  '48': {px: '48px', rem: '3rem'},
  '56': {px: '56px', rem: '3.5rem'},
  '64': {px: '64px', rem: '4rem'},
  '72': {px: '72px', rem: '4.5rem'},
  '80': {px: '80px', rem: '5rem'},
  '88': {px: '88px', rem: '5.5rem'},
  '96': {px: '96px', rem: '6rem'},
  '112': {px: '112px', rem: '7rem'},
  '120': {px: '120px', rem: '7.5rem'},
  '128': {px: '128px', rem: '8rem'},
  '136': {px: '128px', rem: '8.5rem'},
  '144': {px: '144px', rem: '9rem'},
  '160': {px: '160px', rem: '10rem'}
};

export const fontScaleBody = 
[14,16,18,20,24,32,36,40,48,56,64];

export const RGtheme = {
  'typeStyles': {
    'mHeader': {
      fontsize: fontScale['32'].rem,
      lineHeight: fontScale['48'].rem,
    },
    'dHeader': {
      fontsize: fontScale['40'].rem,
      lineHeight: fontScale['48'].rem,
    },
    'dBody': {
      fontsize: fontScale['24'].rem,
      lineHeight: fontScale['40'].rem,
    },
    'mBody': {
      fontsize: fontScale['24'].rem,
      lineHeight: fontScale['32'].rem,
    },
    'dBioText': {
      fontsize: fontScale['48'].rem,
      lineHeight: fontScale['80'].rem,      
    },
    'mBioText': {
      fontsize: fontScale['32'].rem,
      lineHeight: fontScale['48'].rem,
    },
    'dJumbo': {
      fontsize: fontScale['48'].rem,
      lineHeight: fontScale['56'].rem,
    },
    'mJumbo': {
      fontsize: fontScale['32'].rem,
      lineHeight: fontScale['48'].rem,
    },
    'viewportLabel': {
      fontsize: fontScale['20'].rem,
      lineHeight: fontScale['32'].rem,
    },
  },
  'strokes': {
    '1': {solid: '1px solid', dotted: '1px dotted'},
    '2': {solid: '2px solid', dotted: '2px dotted'},
    '4': {solid: '4px solid', dotted: '4px dotted'},
  },
  'moduleSpacing': {
    default: spacers['48'].rem,
    mobileS: spacers['56'].rem,
    mobileM: spacers['72'].rem,
    mobileL: spacers['72'].rem,
    tablet: spacers['72'].rem,
    laptop: spacers['240'].rem,
    laptopL: spacers['240'].rem,
    desktop: spacers['240'].rem,
    desktopL: spacers['240'].rem,
  }

};


const sketchLayoutSettings = {"layout":{"drawVertical":1,"totalWidth":1664,"horizontalOffset":128,"numberOfColumns":6,"guttersOutside":0,"gutterWidth":24,"columnWidth":257.3333333333333,"drawHorizontal":1,"gutterHeight":16,"rowHeightMultiplication":8,"drawHorizontalLines":0,"isEnabled":1},"grid":{"gridSize":24,"thickGridTimes":8,"isEnabled":0}};

export const DesignSystem = {
  "Theme": {
    "breakpoints": {
      "ms": "0px",
      "mm": "368px",
      "ml": "480px",
      "ds": "768px",
      "dm": "1080px",
      "dl": "1280px",
      "dxl": "1440px",
      "dxxl": "1920px",
    },

    "colors": {
      "black": "#000",
      "white": "#fff",
      "grey": "#ececec",
      "gray": {
        "100": "#f7fafc",
        "200": "#edf2f7",
        "300": "#e2e8f0",
        "400": "#cbd5e0",
        "500": "#a0aec0",
        "600": "#718096",
        "700": "#4a5568",
        "800": "#2d3748",
        "900": "#1a202c",
      },
    },
    "spacing": {
      "px": "1px",
      "0": "0",
      "s1": "4px",
      "s2": "8px",
      "s3": "16px",
      "s4": "24px",
      "s5": "32px",
      "s6": "40px",
      "s7": "48px",
      "s8": "56px",
      "s9": "64px",
      "s10": "96px",
      "s11": "128px",
      "s12": "160px",
    },
    "borderRadius": {
      "none": "0",
      "sm": "8px",
      "default": "16px",
      "lg": "40px",
      "full": "9999px",
    },
    "borderWidth": {
      "default": "1px",
      "0": "0",
      "2": "2px",
      "4": "4px",
      "8": "8px",
    },
    "boxShadow": {
      "default": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "md": "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      "lg": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "xl": "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      "inner": "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      "outline": "0 0 0 3px rgba(66, 153, 225, 0.5)",
      "none": "none",
    },
    "container": {
      "contentWidth": "?per breakpoint customize this?",
      "contentMaxWidth": "1334px",
    },
    "fontFamily": {
      "body": ["Calibre", "sans"],
      "sans": [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "Noto Sans",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji",
      ],
      "serif": [
        "Georgia",
        "Cambria",
        "Times New Roman",
        "Times",
        "serif",
      ],
      "mono": [
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace",
      ],
    },
    "fontSize": [ "14px","16px","18px","20px","24px","28px","32px","40px","48px","56px","64px","72px","80px","88px","96px" ],
    "fontWeight": {
      "hairline": "100",
      "thin": "200",
      "light": "300",
      "normal": "400",
      "medium": "500",
      "semibold": "600",
      "bold": "700",
      "extrabold": "800",
      "black": "900",
    },
    "letterSpacing": {
      "tighter": "-0.05em",
      "tight": "-0.025em",
      "normal": "0",
      "wide": "0.025em",
      "wider": "0.05em",
      "widest": "0.1em",
    },
    "lineHeight": {
      "none": "1",
      "tight": "1.25",
      "snug": "1.375",
      "normal": "1.5",
      "relaxed": "1.625",
      "loose": "2",
    },
  }
};
