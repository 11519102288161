import React from 'react';

import styled from 'styled-components';

import { screenMargins, device, contentMaxWidth } from '../components/utils/designsystem';

const ResponsivePageMargins = styled.div`

  @media ${device.mobileS} {
    padding-left: ${screenMargins.mobileS};
    padding-right: ${screenMargins.mobileS};
  }
  @media ${device.mobileM} {
    padding-left: ${screenMargins.mobileM};
    padding-right: ${screenMargins.mobileM};
  }
  @media ${device.mobileL} {
    padding-left: ${screenMargins.mobileL};
    padding-right: ${screenMargins.mobileL};
  }
  @media ${device.tablet} {
    padding-left: ${screenMargins.tablet};
    padding-right: ${screenMargins.tablet};
  }

  @media ${device.laptop} {
    padding-left: ${screenMargins.laptop};
    padding-right: ${screenMargins.laptop};
  }
  @media ${device.laptopL} {
    padding-left: ${screenMargins.laptopL};
    padding-right: ${screenMargins.laptopL};
    
  }
  @media ${device.desktop} {
    max-width: ${contentMaxWidth.body};
    margin-right: auto;
    margin-left: auto;
    padding-left: ${screenMargins.desktop};
    padding-right: ${screenMargins.desktop};
  }
  @media ${device.desktopL} {
    max-width: ${contentMaxWidth.body};

    padding-left: ${screenMargins.desktopL};
    padding-right: ${screenMargins.desktopL};
  }
`;

export const ApplyLayoutFrame = ({children}) => {
    return (
      <ResponsivePageMargins>
        {children}
      </ResponsivePageMargins>
    )
};