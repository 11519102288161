import React from 'react';
import { ApplyLayoutFrame as SnapToPageGrid } from "../frames/ResponsiveModuleContainer";
import styled from 'styled-components';
import { device, gridGutter, RGtheme, spacers } from './utils/designsystem';
import { StyledDash } from '../components/Dash';

const StyledFooter = styled.footer`
  
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};
  color: #222;

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

  padding-bottom: ${spacers['184'].px};

`;

const Row = styled.div`

  @media ${device.mobileS} {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    grid-gap: ${gridGutter.mobileS.rem};
    border-top:  ${RGtheme.strokes['4'].solid};
  }

  @media ${device.laptop} {
    grid-gap: ${gridGutter.laptop.rem};
  }

  @media ${device.laptopL} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    
    grid-gap: ${gridGutter.laptopL.rem};
  }
  
`;

const RowItem = styled.p`

  padding-top: ${spacers['24'].px};
  padding-bottom: ${spacers['24'].px};

  @media ${device.laptopL} {
    padding-top: ${spacers['24'].px};
    padding-bottom: ${spacers['128'].px};
  }

`;

const PageFooter = () => { 
  return (
    
    <StyledFooter>
       <SnapToPageGrid>
          <Row>
            <RowItem>Rob Goerke<br />ACD, UI/UX Lead</RowItem>
            <div></div>
            <RowItem>Contact—<br /><a href="mailto:r.goerke@gmail.com">r.goerke@gmail.com</a></RowItem>
            <RowItem><a href="http://www.linkedin.com/in/rob-goerke-2153725">LinkedIn</a>{'.'}<br /><a href="http://www.instagram.com/robgrk/">Instagram</a>{'.'}<br /><a href="http://open.spotify.com/artist/1yyzBJ4LFn1VDKaIMixD93">Spotify</a>{'.'}</RowItem>
          </Row>
          <StyledDash />
        </SnapToPageGrid>
    </StyledFooter>

)}
export default PageFooter
