import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import { fontScale, spacers, RGtheme, device } from "./utils/designsystem"

const StyledNavList = styled(motion.ul)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StyledNavItem = styled(motion.li)`
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;

  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};
  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

  margin-right: ${spacers["32"].px};

  $:last {
    margin-right: 0px;
  }

  $:hover {
    text-decoration: underline;
  }

  ::selected {
    text-decoration: line-through;
  }
`

const StyledNumber = styled.span`
  font-size: ${fontScale["14"].px};
  line-height: ${fontScale["20"].px};
  padding-right: ${spacers["16"].px};
`

const list = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}


const PageNavInline = props => {
  return (
    <StyledNavList
      variants={list}
      initial={"hidden"}
      animate={"visible"}
      exit={{ opacity: 0 }}
    >
      <StyledNavItem
        selected
        variants={list}
        className={props.selectedRoute === "index" ? "selected" : ""}
      >
        <StyledNumber>01</StyledNumber>
        <Link to="/">Design</Link>
      </StyledNavItem>
      <StyledNavItem
        variants={list}
        className={props.selectedRoute === "music" ? "selected" : ""}
      >
        <StyledNumber>02</StyledNumber>
        <Link to="/music">Music</Link>
      </StyledNavItem>
      <StyledNavItem
        className={props.selectedRoute === "profile" ? "selected" : ""}
        variants={list}
      >
        <StyledNumber>03</StyledNumber>
        <Link to="/about">Profile</Link>
      </StyledNavItem>
    </StyledNavList>
  )
}

export default PageNavInline
