import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types'

import { Link as GatsbyLink } from "gatsby";

import { fontScale, spacers } from "./utils/designsystem";

import MobileNav from "../components/MobileNav"

const NavContainer = styled.nav`
  background: ececec;
  overflow: hidden;
  position: fixed;

  width: 100%;
  height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr;
  align-self: center;
  
  z-index: 55;
`;


const StyledModalNavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 400px;
`;

const StyledNavItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${spacers["64"].px};
  width: 100%;

  font-size: ${fontScale["48"].px};
  line-height: ${fontScale["56"].px};
`;

const StyledNumber = styled.span`

  font-size: ${fontScale["14"].px};
  line-height: ${fontScale["20"].px};
  flex-basis: 56px;
  text-align: center;
  text-justify: center;
  padding-right: ${spacers["24"].px};
  
`;

const NavLinkActiveStyle = {
  textDecoration: "line-through",
};

const LinkStyle = {
  'width': "100%",
  'textAlign': "center",
  'flexBasis': "auto",
};

var classNames = require("classnames");

class PageNavModal extends React.Component {

  render() {
    const navControlClass = classNames({
      "rg-navigation fadein": true,
      "bg-grey": true,
      block: this.props.navIsVisible,
      hidden: !this.props.navIsVisible,
    })

    return (
      <NavContainer className={navControlClass}>
            <MobileNav label='Close' placement="InModalMenu" onClickCallBack={this.props.callToggleNavModal}></MobileNav>

        <StyledModalNavList className="rg-container">
          <StyledNavItem>
            {/* <StyledNumber>01</StyledNumber> */}
            <GatsbyLink 
                as="Link" 
                to="/"
                activeStyle={NavLinkActiveStyle}
                style={LinkStyle}
            >
            Design
            </GatsbyLink>
          </StyledNavItem>
          <StyledNavItem>
            {/* <StyledNumber>02</StyledNumber> */}
            <GatsbyLink 
                as="Link" 
                to="/music"
                activeStyle={NavLinkActiveStyle}
                style={LinkStyle}
                >
              Music
            </GatsbyLink>
          </StyledNavItem>
          <StyledNavItem>
            {/* <StyledNumber>03</StyledNumber> */}
            <GatsbyLink 
                as="Link" 
                to="/about"
                activeStyle={NavLinkActiveStyle}
                style={LinkStyle}
            >
              Profile
            </GatsbyLink>
          </StyledNavItem>

        </StyledModalNavList>

      </NavContainer>
    )
  }
}

export default PageNavModal

PageNavModal.propTypes = {
  selectedRoute: PropTypes.string,
  navIsVisible: PropTypes.bool,
  callToggleNavModal: PropTypes.func
}