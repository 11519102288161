import styled from 'styled-components';
import { stroke, device } from './utils/designsystem'
import { motion } from "framer-motion"


export const StyledDash = styled(motion.div)`
    display: inline-block;
    
    border-top: ${stroke['4'].solid};
    
    align-self: center;
    
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.tablet} {
        width: auto;
    }
`;
