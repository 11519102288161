import React, { useEffect } from "react"
import { motion, useMotionValue } from "framer-motion"

export default function CustomCursor() {
  
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 16);
      cursorY.set(e.clientY - 16);
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  let cursorStyle = {
    position: "fixed",
    left: "0",
    top: "0",
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    backgroundColor: "#fff",
    cursor: 'none',
    pointerEvents: 'none',
    translateX: cursorX,
    mixBlendMode: 'difference',
    zIndex: '999',
    translateX: cursorX,
    translateY: cursorY
  }

  return <motion.div
  className="cursor"
  style={cursorStyle}
  />
}
