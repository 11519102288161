import React from "react"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import styled from "styled-components"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

import PageNavInline from "../components/PageNavInline"

import {
  device,
  screenMargins,
  contentMaxWidth,
  spacers,
  RGtheme,
} from "./utils/designsystem"

import MenuIcon from "../images/assets/svg/hamburger_jumbo.svg"
import Logo from "../images/assets/rg-logo.svg"

const LogoWrapper = styled.div`
  width: 40px;
`

const StyledMobileHeader = styled.div`
  position: absolute;
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding-top: ${spacers["32"].px};
  padding-bottom: ${spacers["32"].px};

  @media ${device.mobileS} {
    padding-left: ${screenMargins.mobileS};
    padding-right: ${screenMargins.mobileS};
  }
  @media ${device.mobileM} {
    padding-left: ${screenMargins.mobileM};
    padding-right: ${screenMargins.mobileM};
  }
  @media ${device.mobileL} {
    padding-left: ${screenMargins.mobileL};
    padding-right: ${screenMargins.mobileL};
  }
  @media ${device.tablet} {
    padding-left: ${screenMargins.tablet};
    padding-right: ${screenMargins.tablet};
  }
  @media ${device.laptop} {
    display: none;
  }
`

const StyledDesktopHeader = styled(motion.div)`
  display: none;
  position: absolute;
  width: 100%;

  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    padding-left: ${screenMargins.laptop};
    padding-right: ${screenMargins.laptop};

    padding-top: ${spacers["64"].px};
    padding-top: ${spacers["64"].px};
  }
  @media ${device.laptopL} {
    padding-left: ${screenMargins.laptopL};
    padding-right: ${screenMargins.laptopL};
  }
  @media ${device.desktop} {
    margin-left: auto;
    margin-right: auto;
    padding-left: ${screenMargins.desktop};
    padding-right: ${screenMargins.desktop};
  }
  @media ${device.desktopL} {

    padding-left: ${screenMargins.desktopL};
    padding-right: ${screenMargins.desktopL};

  }
`

const StyledButton = styled(props => <button {...props} />)`
  display: flex;
  align-items: center;
  grid-gap: 16px;
`

const Label = styled.div`
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }
`

const DesktopHeaderVariants = {

}

const PageHeader = props => (
  <Headroom pinStart={0}>
    <StyledDesktopHeader
   
      initial={{
       
        top: '-24px'
      }}
      animate={{
      
        top: 0
      }}
    >
      <Link to="/">
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Link>
      <div className="text-right self-center">
        <PageNavInline selectedRoute="" />
      </div>
    </StyledDesktopHeader>

    <StyledMobileHeader>
      <Link to="/">
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Link>
      <StyledButton onClick={props.callToggleNavModal}>
        <Label>Menu</Label>
        <MenuIcon />
      </StyledButton>
    </StyledMobileHeader>
  </Headroom>
)

export default PageHeader

PageHeader.propTypes = {
  callToggleNavModal: PropTypes.func.isRequired,
}
