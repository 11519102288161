import React, { Component } from 'react'
import { Link as GatsbyLink} from "gatsby"
import styled from 'styled-components'
import PropTypes from "prop-types"

import { RGtheme, device, screenMargins, spacers } from "./utils/designsystem";

import Logo from "../images/assets/rg-logo.svg";

import CloseIcon from "../images/assets/svg/close_jumbo.svg";

const StyledModalHeader = styled.div`

  padding-top: ${spacers['32'].px};
  padding-bottom: ${spacers['32'].px};

  @media ${device.mobileS} {
    padding-left: ${screenMargins.mobileS};
    padding-right: ${screenMargins.mobileS};
  }
  @media ${device.mobileM} {
    padding-left: ${screenMargins.mobileM};
    padding-right: ${screenMargins.mobileM};
  }
  @media ${device.mobileL} {
    padding-left: ${screenMargins.mobileL};
    padding-right: ${screenMargins.mobileL};
  }
  @media ${device.tablet} {
    padding-left: ${screenMargins.tablet};
    padding-right: ${screenMargins.tablet};
  }
  @media ${device.laptop} {
    padding-left: ${screenMargins.laptop};
    padding-right: ${screenMargins.laptop};
   
    padding-top: ${spacers['64'].px};
    padding-top: ${spacers['64'].px};
  }
`;

const LogoWrapper = styled.div`
  width: ${spacers["40"].px};
`;

const InnerFrame = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const StyledButton = styled(props => <button {...props} />)`
    display: flex;
    align-items: center;
    grid-gap: 16px;
`;

const Label = styled.div`
  
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};
  
  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

`;

const StyledCloseIcon = styled(props => <CloseIcon {...props} />)`
  
`;

export default class MobileNav extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
      this.props.onClickCallBack();
    }

    render() {
        return (
            <StyledModalHeader>
            <InnerFrame>
            
                <GatsbyLink to="/">
                  <LogoWrapper>
                    <Logo />
                  </LogoWrapper>
                </GatsbyLink>
             
                <StyledButton onClick={this.handleClick}>
                  <Label>{this.props.label}</Label>
                  <StyledCloseIcon />
                </StyledButton>
             
            </InnerFrame>
          </StyledModalHeader>
        )
    }
}

MobileNav.propTypes = {
  label: PropTypes.string.isRequired,
  placement: PropTypes.string,
  onClickCallBack: PropTypes.func.isRequired,
}
