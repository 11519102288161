import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"

import PageHeader from "../components/PageHeader"
import PageNavModal from "../components/PageNavModal"
import PageFooter from "../components/Footer"
import { color } from "../components/utils/designsystem"
import CustomCursor from "../components/CustomCursor"

import "../styles/app-layout-template.css"

/* Global Level Application Layout - Wraps around pages
Dynamic: toggle Navigation Modal State bool */

class AppLayoutTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navIsVisible: false,
    }
    this.toggleNavModal = this.toggleNavModal.bind(this)
  }

  toggleNavModal = () => {

    this.setState(prevState => ({
      navIsVisible: prevState.navIsVisible ? false : true,
    }))
  }

  render() {
    const StyledViewport = styled.div`
      background-color: ${color["bg-grey"].hex};
    `

    const ApplyPageFadeInOut = styled(motion.div)``

    return (
      <AnimatePresence>
        <StyledViewport>
          <PageNavModal
            navIsVisible={this.state.navIsVisible}
            callToggleNavModal={this.toggleNavModal}
            selectedRoute="index"
          />

          <ApplyPageFadeInOut
            initial={{  opacity: 0  }}
            animate={{  opacity: 1  }}
            exit={{ opacity: 0  }}
            transition={{ duration: 0.8, type: "tween", ease: "backIn" }}
          >
            <CustomCursor />
            <PageHeader callToggleNavModal={this.toggleNavModal} />
            {this.props.children}
            <PageFooter />'
          </ApplyPageFadeInOut>
        </StyledViewport>
      </AnimatePresence>
    )
  }
}

export default AppLayoutTemplate
